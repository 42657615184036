<template>
  <div>
    <secao-banner></secao-banner>

    <secao-como-funciona></secao-como-funciona>

    <secao-recursos></secao-recursos>

    <secao-valor></secao-valor>

    <secao-duvidas></secao-duvidas>

    <secao-faca-teste></secao-faca-teste>

    <secao-segmentos></secao-segmentos>

    <secao-parceiros></secao-parceiros>

  </div>
</template>
<script async>
/* eslint-disable */
export default {
  name: 'Home',
  components: {
    SecaoBanner: () => import('./SecaoBanner.vue'),
    SecaoComoFunciona: () => import('./SecaoComoFunciona.vue'),
    SecaoRecursos: () => import('./SecaoRecursos.vue'),
    SecaoFacaTeste: () => import('./SecaoFacaTeste.vue'),
    SecaoValor: () => import('./SecaoValor.vue'),
    SecaoDuvidas: () => import('./SecaoDuvidas.vue'),
    SecaoSegmentos: () => import('./SecaoSegmentos.vue'),
    SecaoParceiros: () => import('./SecaoParceiros.vue'),
  },
  methods: {

  }
}
</script>
<style scoped>
</style>
